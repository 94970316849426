import React from 'react';
import arrow from "../Assests/arrow.png";

const Location = () => {
    return (
        <section id='location' className="bg-[#F8F8F8] p-12">
            <p className="text-lg py-2 font-semibold transition duration-300 mt-4 text-[#c27b7f]">DLF Gardencity Enclave</p>
            <h2 className="text-3xl font-bold text-center mb-2 text-[#2f4858]">Location</h2>

            <div className="flex flex-col items-center z-0"> 
                <img src={arrow} alt='arrow' className='m-auto mb-8 text-red' width={200} />
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe 
                            width="100%" 
                            height="450" 
                            id="gmap_canvas" 
                            src="https://maps.google.com/maps?q=gurgaon%20sector%2092&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                            frameBorder="0" 
                            scrolling="no" 
                            marginHeight="0" 
                            marginWidth="0">
                        </iframe>
                        <a href="https://123movies-i.net">123movies</a>
                        <br />
                        <style>
                            {`.mapouter{position:relative;text-align:right;height:450px;width:100%;}
                            .gmap_canvas {overflow:hidden;background:none!important;height:450px;width:100%;}`}
                        </style>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Location;
