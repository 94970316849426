import React from 'react';
import arrow from "../Assests/arrow.png";
import { GiLift, GiPartyFlags } from "react-icons/gi";
import { FaParking, FaTableTennis, FaSwimmingPool } from "react-icons/fa";
import { CgGym } from "react-icons/cg";

const Amenities = () => {
    return (
        <section id='amenities' className="bg-[#F8F8F8] p-12">
            <p className="text-lg py-2 font-semibold transition duration-300 mt-4 text-[#c27b7f]">DLF Gardencity Enclave</p>
            <h2 className="text-3xl font-bold text-center mb-2 text-[#2f4858]">Amenities</h2>
            <img src={arrow} alt='arrow' className='m-auto mb-8 text-red' width={200} />

            <div className="grid grid-cols-1 md:grid-cols-6 gap-6">
                <div className="rounded-lg flex flex-col items-center p-4 bg-white border border-gray-200 shadow-lg hover:shadow-none transition-shadow duration-300">
                    <GiLift size={40} className="text-[#c27b7f]" />
                    <p className="mt-2 text-center text-[#2f4858]">Lift</p>
                </div>
                <div className="rounded-lg flex flex-col items-center p-4 bg-white border border-gray-200 shadow-lg hover:shadow-none transition-shadow duration-300">
                    <FaParking size={40} className="text-[#c27b7f]" />
                    <p className="mt-2 text-center text-[#2f4858]">Parking</p>
                </div>
                <div className=" rounded-lg flex flex-col items-center p-4 bg-white border border-gray-200 shadow-lg hover:shadow-none transition-shadow duration-300">
                    <CgGym size={40} className="text-[#c27b7f]" />
                    <p className="mt-2 text-center text-[#2f4858]">Gym</p>
                </div>
                <div className="rounded-lg flex flex-col items-center p-4 bg-white border border-gray-200 shadow-lg hover:shadow-none transition-shadow duration-300">
                    <FaTableTennis size={40} className="text-[#c27b7f]" />
                    <p className="mt-2 text-center text-[#2f4858]">Table Tennis</p>
                </div>
                <div className="rounded-lg flex flex-col items-center p-4 bg-white border border-gray-200 shadow-lg hover:shadow-none transition-shadow duration-300">
                    <FaSwimmingPool size={40} className="text-[#c27b7f]" />
                    <p className="mt-2 text-center text-[#2f4858]">Swimming Pool</p>
                </div>
                <div className="rounded-lg flex flex-col items-center p-4 bg-white border border-gray-200 shadow-lg hover:shadow-none transition-shadow duration-300">
                    <GiPartyFlags size={40} className="text-[#c27b7f]" />
                    <p className="mt-2 text-center text-[#2f4858]">Party Area</p>
                </div>
            </div>
        </section>
    );
}

export default Amenities;
