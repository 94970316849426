import React, { useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import floorPlanImageNormal from '../Assests/plan/1.jpg';
import floorPlanImageBlur from '../Assests/plan/2.jpg';
import CommonModal from '../Utility/Modal';
import arrow from "../Assests/arrow.png"

const FloorPlan = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogHeader, setDialogHeader] = useState('');

  const openDialog = (header) => {
    setDialogHeader(header);
    onOpen();
  };

  return (
    <section id='about' className="bg-white-100 p-12">
      <p className="text-lg py-2 font-semibold transition duration-300 mt-4 text-[#c27b7f]">DLF Gardencity Enclave</p>
      <h2 className="text-3xl font-bold text-center mb-2 text-[#2f4858]">Floor Plan</h2>
      <img src={arrow} alt='arrow' className='m-auto mb-8 text-red' width={200} />
      <div className="max-w-6xl mx-auto py-8" id="plan">
        <div className="flex flex-col items-center justify-center space-y-8 relative md:flex-row md:justify-center md:space-y-0 md:space-x-8">
          <div className="relative flex flex-col items-center">
            <img
              src={floorPlanImageNormal}
              alt="Floor Plan Normal"
              className="w-full md:w-100 h-80 transition duration-300 ease-in-out filter brightness-75 blur-sm"
            />
            {/* Button */}
            <button onClick={() => openDialog('3 BHK + Terrace + Store')} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#dc3545] text-white px-6 py-1.5 rounded shadow-lg transition duration-300 ease-in-out opacity-100">
              Enquire Now
            </button>
            <p className="mt-4 text-lg font-semibold text-center">3 BHK + Terrace + Store</p>
            <CommonModal isOpen={isOpen} onClose={onClose} headerText={dialogHeader} />
          </div>

          <div className="relative flex flex-col items-center">
            <img
              src={floorPlanImageBlur}
              alt="Floor Plan Blur"
              className="w-full md:w-100 h-80 transition duration-300 ease-in-out filter brightness-75 blur-sm"
            />
            {/* Button */}
            <button onClick={() => openDialog('Site Plan')} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#dc3545] text-white px-6 py-1.5 rounded shadow-lg transition duration-300 ease-in-out opacity-100">
              Enquire Now
            </button>
            <p className="mt-4 text-lg font-semibold text-center">Site Plan</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FloorPlan;
