import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import About from '../Components/About'
import Features from '../Components/Features'
import Price from '../Components/Price'
import Amenities from '../Components/Amenities'
import Gallery from '../Components/Gallery'
import Location from '../Components/Location'
import VirtualTour from '../Components/VirtualTour'
import FloorPlan from '../Components/FloorPlan'
import Contact from '../Components/Contact'
import StickyButton from '../Utility/StickyButton'
import StickyBrochure from '../Utility/StickyBrochure'

const Home = () => {
  return (
    <div>
      <StickyButton />
      <StickyBrochure />
        <Navbar/>
        <Header />
        <About />
        <Features />
        <Price />
        <Amenities />
        <Gallery />
        <Location />
        <VirtualTour />
        <FloorPlan />
        <Contact />
        <Footer />
    </div>
  )
}

export default Home