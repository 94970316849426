import React, { useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import aboutImage from '../Assests/About/about.jpg';
import CommonModal from '../Utility/Modal';
import arrow from "../Assests/arrow.png"

const About = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogHeader, setDialogHeader] = useState('');

  const openDialog = (header) => {
    setDialogHeader(header);
    onOpen();
  };
  return (
    <section id='about' className="bg-white-100 p-12">
      <p className="text-lg py-2 font-semibold transition duration-300 mt-4 text-[#c27b7f]">WELCOME TO</p>
      <h2 className="text-3xl font-bold text-center mb-2 text-[#2f4858]">DLF Gardencity Enclave</h2>
      <img src={arrow} alt='arrow' className='m-auto mb-8 text-red' width={200} />
      <div className="flex flex-col md:flex-row items-center justify-between max-w-6xl mx-auto">
        <div className="md:w-1/2">
          <img src={aboutImage} alt="About" className="w-full rounded-lg shadow-lg" />
        </div>
        <div className="md:w-2/4 mt-8 md:mt-0 md:ml-8 text-left">
          <p className="text-md leading-relaxed">
            Indulge in a fresh level of opulence at the Signature Global high-rise venture situated in Sector 71, Gurgaon. Crafted to inspire a feeling of magnificence, this endeavor presents an assortment of facilities and spacious accommodations, both indoors and outdoors. Nestled in a strategic location, the project flaunts effortless access to vital thoroughfares including SPR, Sohna Road, Extn. Road, Clover Leaf, NH48, and Dwarka Expressway, alongside internal pathways across Gurgaon, guaranteeing convenient reach to every nook and cranny of the city.
          </p>
          <p className="mt-5 text-md py-2 font-semibold transition duration-300">POSSESSION: 2026</p>
          
        </div>
      </div>
    </section>
  );
};

export default About;
