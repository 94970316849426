import React, { useState } from 'react';
import arrow from "../Assests/arrow.png";
import CommonModal from '../Utility/Modal';
import { useDisclosure } from '@chakra-ui/react';

const Price = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogHeader, setDialogHeader] = useState('');

  const openDialog = (header) => {
    setDialogHeader(header);
    onOpen();
  };
  return (
    <section id='price' className="bg-white p-12">
      <p className="text-lg py-2 font-semibold transition duration-300 mt-4 text-[#c27b7f]">DLF Gardencity Enclave</p>
      <h2 className="text-3xl font-bold text-center mb-2 text-[#2f4858]">Price List</h2>
      <img src={arrow} alt='arrow' className='m-auto mb-8 text-red' width={200} />

      <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl p-6 border border-gray-200" style={{backgroundImage: "linear-gradient(135deg, #fdfcfb 0, #e2d1c3 100%)"}}>
        <div className="p-8 text-center">
          <div className="uppercase tracking-wide text-2xl text-indigo-500 font-semibold text-[#676768]">3 BHK + Terrace + Store</div>
          <h3 className="block mt-1 text-xl leading-tight font-medium text-black">Rs. 2.50 Cr. Onwards</h3>
          <p className="mt-2 text-gray-700 font-bold">SALEABLE AREA</p>
          <p className="mt-2 text-gray-500">180 Sq. yards</p>
          <button onClick={() => openDialog('Price List')} className="mt-4  text-[#c27b7f]  py-2 px-4 rounded hover:bg-black hover:text-white transition duration-300" style={{border:"1px solid"}}>
            Buy Now
          </button>
          <CommonModal isOpen={isOpen} onClose={onClose} headerText={dialogHeader} />
        </div>
      </div>
    </section>
  )
}

export default Price;
