import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';
import { Checkbox, Text, useMediaQuery, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import arrow from "../Assests/arrow.png"

const Contact = () => {
    const form = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useToast();
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const navigate = useNavigate();
    const sendEmail = (e) => {
        e.preventDefault();

        const formData = new FormData(form.current);
        formData.append('source', 'Signature');
        const requiredFields = ["user_name", "user_phone", "user_email"];
        const isAnyFieldEmpty = requiredFields.some((field) => !form.current[field].value);

        if (isAnyFieldEmpty) {
            toast({
                title: "Please fill in all the details",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsSubmitting(true);

        emailjs
            .sendForm("service_bnqs3nf", "template_6znyofw", form.current, {
                publicKey: "3q48ooOOxL1LhxMEE",
            })
            .then(
                () => {
                    console.log("SUCCESS!");
                    setIsSubmitting(false);
                    toast({
                        title: "Thanks For Your Interest",
                        description: "Our Team will Contact you soon",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    });
                    navigate("/thank-you");
                },
                (error) => {
                    console.log("FAILED...", error.text);
                    setIsSubmitting(false);
                    alert("Error submitting form");
                }
            );
    };
    return (
        <section id='contact' className="bg-[#fafad2] p-12">
            <p className="text-lg py-2 font-semibold transition duration-300 mt-4 text-[#c27b7f]">DLF Gardencity Enclave</p>
            <h2 className="text-3xl font-bold text-center mb-2 text-[#2f4858]">Contact Us</h2>
            <img src={arrow} alt='arrow' className='m-auto mb-8 text-red' width={200} />
            {/* <div className="absolute inset-0 bg-[#fafad2] opacity-100"></div> */}
            <div className="relative z-10 max-w-6xl mx-auto px-4 flex flex-col md:flex-row items-center justify-between w-full h-full">
                <div className="mr-2">
                    <h2 className="text-2xl font-bold text-center mb-2 text-[#2f4858]">FOR ANY QUERY CONTACT US:</h2>
                    <p className="text-lg py-2  transition duration-300 mt-4 text-left">DLF Gardencity Enclave <br /> Sector 93, Gurugram <br />
                        Haryana, India - 122016</p>
                </div>
                <div className="bg-[#0b1c4b]  p-6 rounded-lg shadow-lg md:w-1/3 mt-8 md:mt-0 flex items-center justify-center">
                    <div>
                        <h2 className="text-md  mb-4 text-jsuitfy text-white">For more information, get in contact with us by filling in the form below.</h2>
                        <form className="space-y-4" ref={form} onSubmit={sendEmail}>
                            <input
                                type="text"
                                placeholder="Name"
                                name="user_name"
                                className="w-full p-2 border border-gray-300 rounded-lg"
                            />
                            <input
                                type="text"
                                placeholder="Phone Number"
                                name="user_phone"
                                className="w-full p-2 border border-gray-300 rounded-lg"
                            />
                            <input
                                type="email"
                                placeholder="Email"
                                name="user_email"
                                className="w-full p-2 border border-gray-300 rounded-lg"
                            />
                            <input type="hidden" name="source" value="Signature" />
                            <Checkbox defaultChecked>
                                <Text fontSize={"xs"} textAlign={"left"} color={"white"}>
                                    I agree and authorize team to contact me. This will override the registry with DNC / NDNC
                                </Text>
                            </Checkbox>
                            <button
                                type="submit"
                                className="w-full bg-[#d88922] text-white p-2 rounded-full "
                                isLoading={isSubmitting}
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
