import React, { useState } from 'react';
import arrow from "../Assests/arrow.png"
import aboutImage from "../Assests/Gallery/dining.jpg";

const gallery = [
    "Developed by a premier developer.",
    "Rich greenery covering 80% of the area.",
    "RERA approved.",
    "Low population density.",
    "Lavish clubhouse with modern amenities.",
    "Reserved parking spaces."
  ];

const Gallery = () => {

  return (
    <section id='gallery' className="bg-white-100 p-12">
      <p className="text-lg py-2 font-semibold transition duration-300 mt-4 text-[#c27b7f]">DLF Gardencity Enclave</p>
      <h2 className="text-3xl font-bold text-center mb-2 text-[#2f4858]">Gallery</h2>
      <img src={arrow} alt='arrow' className='m-auto mb-8 text-red' width={200} />
      <div className="flex flex-col md:flex-row items-center justify-between max-w-6xl mx-auto">
        <div className="md:w-1/2">
          <img src={aboutImage} alt="About" className="w-full rounded-lg shadow-lg" />
        </div>
        <div className="md:w-2/4 mt-8 md:mt-0 md:ml-8 text-left">
        <p className="text-xl px-6 py-2 font-semibold transition duration-300 mt-4 text-[#c27b7f]">Project Highlights</p>
        <div className=" p-6">
        <ul className="grid grid-cols-1 md:grid-cols-1 gap-4">
          {gallery.map((feature, index) => (
            <li key={index} className="flex items-center">
              <svg
                className="w-6 h-6 text-green-500 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              {feature}
            </li>
          ))}
        </ul>
      </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
