import React from 'react';
import arrow from "../Assests/arrow.png";

const featuresList = [
    "Revel in 80% open living spaces.",
    "Admire beautifully landscaped gardens",
    "Superb connectivity to KMP and SPR.",
    "24/7 security for total peace of mind.",
    "Secure basement storage available.",
    "Dedicated lifts for each home.",
    "One A/C residence per floor.",
    "Stunning views of 90 acres of open space.",
    "Access to NH-48 and Dwarka Expressway."
  ];

const Features = () => {
  return (
    <section id='features' className="bg-black p-12">
      <p className="text-lg py-2 font-semibold transition duration-300 mt-4 text-[#c27b7f]">DLF Gardencity Enclave</p>
      <h2 className="text-3xl font-bold text-center mb-2 text-white">Key Features</h2>
      <img src={arrow} alt='arrow' className='m-auto mb-8 text-white' width={200} />
      <div className="bg-black p-6">
        <ul className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {featuresList.map((feature, index) => (
            <li key={index} className="flex items-center text-white">
              <svg
                className="w-6 h-6 text-green-500 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Features;
