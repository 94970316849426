import React from 'react';
import video from "../Assests/Tour/play.png";
import backgroundImage from "../Assests/Gallery/about.jpg";
import arrow from "../Assests/arrow.png"

const VirtualTour = () => {
    return (
        <section id="tour" >
            <div className="relative h-56 overflow-hidden" style={{border:"5px solid black"}}>
                <div className="absolute inset-0 bg-center bg-cover bg-black h-56" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
                <div className="absolute inset-0 flex justify-center items-center">
                <div className="absolute inset-0 bg-[rgba(31,37,48,0.5)]"></div>
                    <div className="text-center">
                        <img src={video} alt='play-video' className="w-20 h-20 mx-auto mb-4" />
                        <h2 className="text-3xl font-bold text-center  text-white">Virtual Tour</h2>
                        <p className="text-lg py-2 font-semibold transition duration-300  text-white">DLF Gardencity Enclave</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VirtualTour;
