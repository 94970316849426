import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';
import { Checkbox, Text, useMediaQuery, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../Assests/Banner1.png';

const Header = () => {
    const form = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useToast();
    const [isMobile] = useMediaQuery('(max-width: 960px)');
    const navigate = useNavigate();
    const sendEmail = (e) => {
        e.preventDefault();

        const formData = new FormData(form.current);
        formData.append('source', 'Signature');
        const requiredFields = ["user_name", "user_phone", "user_email"];
        const isAnyFieldEmpty = requiredFields.some((field) => !form.current[field].value);

        if (isAnyFieldEmpty) {
            toast({
                title: "Please fill in all the details",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsSubmitting(true);

        emailjs
            .sendForm("service_bnqs3nf", "template_6znyofw", form.current, {
                publicKey: "3q48ooOOxL1LhxMEE",
            })
            .then(
                () => {
                    console.log("SUCCESS!");
                    setIsSubmitting(false);
                    toast({
                        title: "Thanks For Your Interest",
                        description: "Our Team will Contact you soon",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    });
                    navigate("/thank-you");
                },
                (error) => {
                    console.log("FAILED...", error.text);
                    setIsSubmitting(false);
                    alert("Error submitting form");
                }
            );
    };

    return (
        <>
        {isMobile ? 
        <>
        <header id='home' className="relative flex flex-col items-center">
            <img src={backgroundImage} alt="Background" className="w-full h-auto object-cover" />
            <div className={`relative z-10 max-w-7xl mx-auto ${isMobile ? 'mt-4' : 'absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'} flex flex-col md:flex-row items-center justify-between w-full`}>
                <div className="text-white space-y-4 w-full md:w-1/3 bg-white p-4 rounded-lg shadow-lg mb-4">
                    <div className="bg-white text-black p-2 rounded-[30px_0px_30px_0px]">
                        <p className="text-xl font-bold text-green-700">Low Rise 3 BHK Independent Floors</p>
                        <p className="text-lg">Starting Price Rs. 2.50 Cr.</p>
                        <p className="text-lg">Booking Amount Rs. 5 Lakh</p>
                        <div className="bg-green-500 p-4 rounded mt-4">
                            <ul className="list-disc pl-6 text-white" style={{ border: "3px dashed white" }}>
                                <li>25:25:50 Payment Plan</li>
                                <li>Exclusive Car Parking</li>
                                <li>Dedicated Lift For Each Home</li>
                            </ul>
                        </div>
                        <p className="text-lg mt-4">RERA Registered Project</p>
                    </div>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-1/3">
                    <h2 className="text-2xl font mb-4">Get In Touch</h2>
                    <form className="space-y-4" ref={form} onSubmit={sendEmail}>
                        <input
                            type="text"
                            placeholder="Name"
                            name="user_name"
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                        <input
                            type="text"
                            placeholder="Phone Number"
                            name="user_phone"
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            name="user_email"
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                        <input type="hidden" name="source" value="Signature" />
                        <Checkbox defaultChecked>
                            <Text fontSize={"xs"} textAlign={"left"}>
                                I agree and authorize team to contact me. This will override the registry with DNC / NDNC
                            </Text>
                        </Checkbox>
                        <div className="flex items-center space-x-4">
                            <button
                                type="submit"
                                className="bg-[#d88922] text-white py-2 px-4 rounded-full"
                            >
                                Submit
                            </button>
                            <a
                                href="tel:+918178593744"
                                className="py-2 px-4 bg-[#198754] text-white rounded-full hover:bg-[#198754]"
                            >
                                +91 8178593744
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </header>
        </>:
        <>
        <header
            id='home'
            className="relative bg-cover bg-center h-screen flex items-center"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="absolute"></div>
            <div className="relative z-10 max-w-7xl mx-auto px-4 flex flex-col md:flex-row items-center justify-between w-full">
                <div className="text-white space-y-4 md:w-1/3 ">
                    <div className="bg-white text-black p-2 rounded-[30px_0px_30px_0px]">
                        <p className="text-xl font-bold text-green-700">Low Rise 3 BHK Independent Floors</p>
                        <p className="text-lg">Starting Price Rs. 2.50 Cr.</p>
                        <p className="text-lg">Booking Amount Rs. 5 Lakh</p>
                        <div className="bg-green-500 p-4 rounded mt-4">
                            <ul className="list-disc pl-6 text-white" style={{border:"3px dashed white"}}>
                                <li>25:25:50 Payment Plan</li>
                                <li>Exclusive Car Parking</li>
                                <li>Dedicated Lift For Each Home</li>
                            </ul>
                        </div>
                        <p className="text-lg mt-4">RERA Registered Project</p>
                    </div>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-lg md:w-1/3 mt-8 md:mt-0">
                    <h2 className="text-2xl font mb-4">Get In Touch</h2>
                    <form className="space-y-4" ref={form} onSubmit={sendEmail}>
                        <input
                            type="text"
                            placeholder="Name"
                            name="user_name"
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                        <input
                            type="text"
                            placeholder="Phone Number"
                            name="user_phone"
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            name="user_email"
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                        <input type="hidden" name="source" value="Signature" />
                        <Checkbox defaultChecked>
                            <Text fontSize={"xs"} textAlign={"left"}>
                                I agree and authorize team to contact me. This will override the registry with DNC / NDNC
                            </Text>
                        </Checkbox>
                        <div className="hidden md:flex items-center space-x-4">
                            <button
                                type="submit"
                                className="bg-[#d88922] text-white py-2 px-4 rounded-full"
                            >
                                Submit
                            </button>
                            <a
                                href="tel:+918178593744"
                                className="py-2 px-4 bg-[#198754] text-white rounded-full hover:bg-[#198754]"
                            >
                                +91 8178593744
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </header>
        </>}
        </>
        
    );
};

export default Header;
